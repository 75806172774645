import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';

function SearchResults({type, data}) {
  const headerTitle = data.length ? 'Results' : 'No Results Found'
  return (
    <div className="search-result">
      <Typography component="h1" variant="h5" align="left">{headerTitle}</Typography>
      { data.length ? (
        <div className="searh-results-data">
          {
            type === 'fact-checks' ? (
              <List>
                {data.map(row => (
                  <ListItem
                    key={`feeds-${row.id}`}
                    style={{padding: '0px'}}
                  >
                    <ListItemText
                      primary={
                        <Typography
                          component="a"
                          href={row.attributes['published-article-url']}
                          target="_blank"
                          style={{color: '#0097a7'}}
                        >
                          {row.attributes['fact-check-title']}
                        </Typography>
                      }
                      secondary={
                        <React.Fragment>
                          <Typography
                            sx={{ display: 'inline' }}
                            component="span"
                            variant="body2"
                            color="textPrimary"
                          >
                            {row.attributes['fact-check-summary']}
                          </Typography>
                        </React.Fragment>
                      }
                    />
                  </ListItem>
                ))}
              </List>
            ) : (
              <List>
                {data.map(row => (
                  <ListItem
                    key={`category-${row.id}`}
                    style={{padding: '0px'}}
                  >
                    <ListItemText
                      primary={
                        <React.Fragment>
                          <Typography
                            sx={{ display: 'inline' }}
                            component="span"
                            variant="body2"
                            color="textPrimary"
                            style={{paddingRight: '5px'}}
                          >
                            [{row.label}]
                          </Typography>
                          <Typography
                            component="a"
                            href={row.url}
                            target="_blank"
                            style={{color: '#0097a7'}}
                          >
                            {row.title}
                          </Typography>
                        </React.Fragment>
                      }
                    />
                  </ListItem>
                ))}
              </List>
            )
          }
        </div>
        ) : null
      }
    </div>
  );
}

export default SearchResults;