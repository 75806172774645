import React from 'react';
import Typography from '@material-ui/core/Typography';

function HelperText() {
  return (
    <div className="App-row" style={{ display: 'inline-block', textAlign: 'left'}}>
      <Typography component="h1" variant="h5">About</Typography>
      &nbsp;
      <Typography component="p">
        Co·Insights is a unique platform that enables a community-led approach to identify, preempt, and respond to misinformation in minoritized communities. We're building Co·Insights with Asian-American and Pacific Islander communities who are greatly underserved by current misinformation intervention efforts. You can learn more about the project at <a href="https://coinsights.org/">https://coinsights.org/</a>
      </Typography>
      &nbsp;
      &nbsp;
      <Typography component="p">
        Please enter a misinformation claim and hit enter. "Search fact-checks" will use our state-of-the-art claim matching technology to find existing fact-checks. "Search COVID-19 preemptive explainers" will run a different machine learning model that tries to place your query into a general information category. Matching queries to preemptive explainers allows us to respond quickly to new claims and cover far more claims than individual fact-checks can.
      </Typography>
      &nbsp;
      &nbsp;
      <Typography component="p">
        <strong>Need some inspiration?</strong> Consider trying one of these <strong>false</strong> claims:
      </Typography>
      <Typography component="div">
        <ul>
          <li key="flu">covid is just the flu</li>
          <li key="microchips">covid vaccines have microchips</li>
          <li key="alkaline">eat alkaline foods to prevent covid</li>
        </ul>
      </Typography>
    </div>
  );
}

export default HelperText;
