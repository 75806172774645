import React from 'react';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import BeatLoader from "react-spinners/BeatLoader";
import HelperText from './HelperText'
import SearchResults from './search/SearchResults'
import logo from './logo.png';
import './App.css';
import './config'

function App() {
  const [keyword, setKeyword] = React.useState('');
  const [searchOption, setSearchOption] = React.useState('fact-checks');
  const [searchData, setSearchData] = React.useState('');
  const [spinner, setSpinner] = React.useState(false);
  const [error, setError] = React.useState(null);

  const handleKeyWordChange = (value) => {
    setKeyword(value);
    setSearchData('');
  };

  const handleOptionsChange = (value) => {
    setSearchOption(value)
    setSearchData('');
    if (keyword.length > 0) {
      setSpinner(true);
      handleSubmit(value);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter' && keyword.length > 0) {
      event.preventDefault();
      setSpinner(true);
      handleSubmit(searchOption);
    }
  }

  const handleSubmit = (optionType) => {
    setError(null);
    const type = optionType === 'fact-checks' ? 'feeds' : 'categorization';
    const urlParams = new URLSearchParams();
    urlParams.set("type", type);
    if (type === 'feeds') {
      urlParams.set("feedsType", 'text');
      urlParams.set("feedsQuery", keyword);
    } else {
      urlParams.set("categorizationQuery", keyword);
    }
    const baseUrl = global.config.feedBaseUrl;
    const feedApiUrl = `${baseUrl}?${urlParams.toString()}`;
    fetch(feedApiUrl)
    .then(response => response.json())
    .then(result => {
      setSpinner(false);
      setSearchData(result);
    })
    .catch(error => {
      setSpinner(false);
      setError(error);
    });
  };

  const handleIconClick = () => {
    handleSubmit(searchOption);
  };

  return (
      <div className="App">
        <Box textAlign="center" >
          <div>
            <img src={logo} className="App-logo" alt="logo" />
          </div>
          <div className="App-row">
            <TextField
              id="outlined-basic"
              variant="outlined"
              label="Enter a misinformation claim and press enter to search"
              onChange={(e) => { handleKeyWordChange(e.target.value); }}
              onKeyPress={handleKeyPress}
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleIconClick}>
                      <SearchIcon/>
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
          </div>
          <div className="App-row">
            <FormControl>
              <RadioGroup row value={searchOption} onChange={(e) => { handleOptionsChange(e.target.value); }}>
                <FormControlLabel value="fact-checks" control={<Radio />} label="Search fact-checks" />
                <FormControlLabel value="covid-19" control={<Radio />} label="Search COVID-19 preemptive explainers" />
              </RadioGroup>
            </FormControl>
          </div>
          <div className="App-row">
            <BeatLoader color="#ff527b" loading={spinner} />
            { error ? (
              <div className="search-error" style={{textAlign: 'left'}}>
                <Typography component="span" variant="body2" style={{color: "red"}}>{error}</Typography>
              </div>
              ): null }
            {searchData ? <SearchResults data={searchData.data} type={searchOption} /> : null}
          </div>
          <div className="App-row">
            <Divider />
            <HelperText />
          </div>
        </Box>
      </div>
  );
}

export default App;
